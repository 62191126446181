<template>
  <div>
    <eden-page-header :title="'Gardeners'" :subtitle="'Add new gardener'" />
    <gardener-form action="add" />
  </div>
</template>

<script>
import GardenerForm from "@/components/Gardeners/Gardener/GardenerForm";
import * as actions from "@/store/action-types";

export default {
  name: "GardenerAdd",
  components: {
    GardenerForm,
  },
  data() {
    return {};
  },
  created() {
    this.$store.dispatch(actions.GET_LOCATION_AREAS_LIST);
  },
};
</script>

<style scoped lang="scss"></style>
